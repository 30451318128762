import React, { useState, useEffect } from 'react';
import { callApi } from '@cloud-ui/utils/api';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Container,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar,
} from '@mui/material';

interface User {
  id: string;
  name: string;
  email: string;
  status: 'Active' | 'Pending';
}

interface ServiceAccount {
  id: string;
  name: string;
  createdAt: string;
}

interface ServiceAccountWithToken extends ServiceAccount {
  token: string;
}

export default function OrganizationDetails({ orgId }: { orgId: string }) {
  const [organization, setOrganization] = useState<{
    name: string;
  } | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[]>([]);
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [userError, setUserError] = useState<string | null>(null);
  const [serviceAccountError, setServiceAccountError] = useState<string | null>(null);
  const [showNewTokenDialog, setShowNewTokenDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [newServiceAccountName, setNewServiceAccountName] = useState(
    `Service Account ${new Date().toISOString().split('T')[0]}`,
  );
  const [newServiceAccount, setNewServiceAccount] = useState<ServiceAccountWithToken | null>(null);
  const [showCopiedSnackbar, setShowCopiedSnackbar] = useState(false);

  const fetchOrganizationDetails = async () => {
    try {
      const response = await callApi(`/organizations/${orgId}`);
      const data = await response.json();
      setOrganization(data);
      const userResponse = await callApi(`/organizations/${orgId}/users`);
      const userData = await userResponse.json();
      setUsers(userData);
      const serviceAccountResponse = await callApi(`/organizations/${orgId}/service-accounts`);
      const serviceAccountData = await serviceAccountResponse.json();
      setServiceAccounts(serviceAccountData);
    } catch (error) {
      console.error('Error fetching organization details:', error);
    }
  };

  useEffect(() => {
    fetchOrganizationDetails();
  }, [orgId]);

  const removeUser = async (userId: string) => {
    try {
      await callApi(`/organizations/${orgId}/users/${userId}`, {
        method: 'DELETE',
      });
      fetchOrganizationDetails();
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  const removeServiceAccount = async (accountId: string) => {
    try {
      await callApi(`/organizations/${orgId}/service-accounts/${accountId}`, {
        method: 'DELETE',
      });
      fetchOrganizationDetails();
    } catch (error) {
      console.error('Error removing service account:', error);
    }
  };

  const addUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setUserError(null);
    try {
      const result = await callApi(`/organizations/${orgId}/users`, {
        method: 'POST',
        body: JSON.stringify({ name: newUserName, email: newUserEmail }),
      });
      if (result.status > 299) {
        throw new Error('Failed to add user. Please try again.');
      }
      setNewUserName('');
      setNewUserEmail('');
      fetchOrganizationDetails();
    } catch (error) {
      console.error('Error adding user:', error);
      setUserError('Failed to add user. Please try again.');
    }
  };

  const createServiceAccount = async () => {
    try {
      setServiceAccountError(null);
      const response = await callApi(`/organizations/${orgId}/service-accounts`, {
        method: 'POST',
        body: JSON.stringify({ name: newServiceAccountName }),
      });
      const data = await response.json();
      setNewServiceAccount(data);
      setShowCreateDialog(false);
      setShowNewTokenDialog(true);
      setNewServiceAccountName('');
      fetchOrganizationDetails();
    } catch (error) {
      console.error('Error creating service account:', error);
      setServiceAccountError('Failed to create service account. Please try again.');
    }
  };

  const copyToken = () => {
    if (newServiceAccount?.token) {
      navigator.clipboard.writeText(newServiceAccount.token);
      setShowCopiedSnackbar(true);
    }
  };

  if (!organization) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 4, mt: 4, bgcolor: 'background.paper' }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {organization.name}
        </Typography>

        <Typography variant="h5" sx={{ mb: 2 }}>
          Users
        </Typography>

        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell align="right">
                    <IconButton edge="end" aria-label="delete" onClick={() => removeUser(user.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h5" sx={{ mb: 2 }}>
          Invite New User
        </Typography>

        <Box
          component="form"
          onSubmit={addUser}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}
        >
          <TextField
            label="Name"
            value={newUserName}
            onChange={(e) => setNewUserName(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Email"
            type="email"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
            required
            fullWidth
          />
          {userError && (
            <Typography color="error" sx={{ mt: 1 }}>
              {userError}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            startIcon={<PersonAddIcon />}
            sx={{ alignSelf: 'flex-start' }}
          >
            Invite User
          </Button>
        </Box>

        <Typography variant="h5" sx={{ mb: 2 }}>
          Service Accounts
        </Typography>

        {serviceAccountError && (
          <Typography color="error" sx={{ mb: 2 }}>
            {serviceAccountError}
          </Typography>
        )}

        {serviceAccounts.length === 0 ? (
          <Paper sx={{ p: 3, mb: 2, textAlign: 'center', bgcolor: 'grey.50' }}>
            <Typography color="text.secondary" sx={{ mb: 2 }}>
              No service accounts yet
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Service accounts are useful for API access and automation. Each service account gets
              its own API key.
            </Typography>
            <Button
              variant="contained"
              startIcon={<KeyIcon />}
              onClick={() => setShowCreateDialog(true)}
            >
              Create Service Account
            </Button>
          </Paper>
        ) : (
          <>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceAccounts.map((account) => (
                    <TableRow key={account.id}>
                      <TableCell component="th" scope="row">
                        {account.name}
                      </TableCell>
                      <TableCell>{new Date(account.createdAt).toLocaleDateString()}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeServiceAccount(account.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Button
              variant="contained"
              startIcon={<KeyIcon />}
              onClick={() => setShowCreateDialog(true)}
              sx={{ mb: 2 }}
            >
              Create Service Account
            </Button>
          </>
        )}

        <Dialog
          open={showCreateDialog}
          onClose={() => setShowCreateDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Create Service Account</DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Service accounts are used for API access and automation. Choose a descriptive name to
              help identify its purpose.
            </Typography>
            <TextField
              label="Service Account Name"
              value={newServiceAccountName}
              onChange={(e) => setNewServiceAccountName(e.target.value)}
              fullWidth
              required
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowCreateDialog(false)}>Cancel</Button>
            <Button
              onClick={createServiceAccount}
              variant="contained"
              disabled={!newServiceAccountName.trim()}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showNewTokenDialog}
          onClose={() => setShowNewTokenDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>New Service Account Created</DialogTitle>
          <DialogContent>
            <Alert severity="warning" sx={{ mb: 2 }}>
              Make sure to copy your API key now. You won't be able to see it again!
            </Alert>
            <Typography variant="subtitle1" gutterBottom>
              Service Account Name:
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <Paper
                sx={{ p: 2, bgcolor: 'grey.100', wordBreak: 'break-all', fontFamily: 'monospace' }}
              >
                {newServiceAccount?.name}
              </Paper>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              API Key:
            </Typography>
            <Paper
              sx={{
                p: 2,
                bgcolor: 'grey.100',
                wordBreak: 'break-all',
                fontFamily: 'monospace',
                cursor: 'pointer',
              }}
              onClick={copyToken}
            >
              {newServiceAccount?.token}
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={copyToken} startIcon={<KeyIcon />}>
              Copy API Key
            </Button>
            <Button onClick={() => setShowNewTokenDialog(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={showCopiedSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowCopiedSnackbar(false)}
          message="API key copied to clipboard"
        />
      </Paper>
    </Container>
  );
}
