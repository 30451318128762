import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import {
  listPluginCollections,
  deletePluginCollection,
  createPluginCollection,
  type PluginCollection,
} from '@cloud-ui/utils/api/redteam';
import { Add as AddIcon, ContentCopy, Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Stack,
} from '@mui/material';
import type { CreatePluginCollectionRequest } from '@shared/dto/redteamConfigs';
import { format } from 'date-fns';

export default function PluginCollectionsListPage() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const { data: pluginCollections, isLoading } = useQuery({
    queryKey: ['pluginCollections'],
    queryFn: listPluginCollections,
  });

  const deletePluginCollectionMutation = useMutation({
    mutationFn: deletePluginCollection,
    onSuccess: async (_, pluginCollectionId) => {
      queryClient.setQueryData(['pluginCollections'], (old: PluginCollection[] | undefined) =>
        old?.filter((pluginCollection) => pluginCollection.id !== pluginCollectionId),
      );
      await queryClient.invalidateQueries({
        queryKey: ['pluginCollections'],
      });
      showToast('Plugin collection deleted successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to delete plugin collection:', error);
      showToast('Failed to delete plugin collection', 'error');
    },
  });

  const copyPluginCollectionMutation = useMutation({
    mutationFn: (pluginCollection: PluginCollection) => {
      const copy: CreatePluginCollectionRequest = {
        name: pluginCollection.name + ' (Copy)',
        plugins: pluginCollection.plugins,
      };

      return createPluginCollection(copy);
    },
    onSuccess: async (result) => {
      await queryClient.invalidateQueries({
        queryKey: ['pluginCollections'],
      });
      showToast('Plugin collection copied successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to copy plugin collection:', error);
      showToast('Failed to copy plugin collection', 'error');
    },
  });

  const handleCopy = async (pluginCollectionId: string) => {
    const pluginCollection = pluginCollections?.find((pc) => pc.id === pluginCollectionId);
    if (!pluginCollection) {
      showToast(`Cannot copy plugin collection, please refresh the page`, 'error');
      return;
    }
    copyPluginCollectionMutation.mutate(pluginCollection);
  };

  const handleDelete = (pluginCollectionId: string) => {
    if (window.confirm('Are you sure you want to delete this plugin collection?')) {
      deletePluginCollectionMutation.mutate(pluginCollectionId);
    }
  };

  return (
    <Box maxWidth="100%" mx="auto" p={3}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Typography variant="h4">Plugin Collections</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate(`${ROUTES.redteam.plugins}/new`)}
        >
          New Plugin Collection
        </Button>
      </div>

      <Card>
        {!isLoading && (!pluginCollections || pluginCollections.length === 0) ? (
          <Box p={4} textAlign="center">
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No Plugin Collections Yet
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={3}>
              Create your first plugin collection to get started
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate(`${ROUTES.redteam.plugins}/new`)}
            >
              Create New Plugin Collection
            </Button>
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Plugins</TableCell>
                <TableCell>Last Updated By</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pluginCollections?.map((pluginCollection) => (
                <TableRow
                  key={pluginCollection.id}
                  hover
                  sx={{
                    cursor: 'pointer',
                    '& td:not(:last-child)': {},
                  }}
                >
                  <TableCell
                    onClick={() => navigate(`${ROUTES.redteam.plugins}/${pluginCollection.id}`)}
                  >
                    {pluginCollection.name || pluginCollection.description || 'Unnamed Collection'}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`${ROUTES.redteam.plugins}/${pluginCollection.id}`)}
                  >
                    {(pluginCollection.plugins ?? []).length > 0 ? (
                      <Tooltip
                        title={
                          <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                            <Stack spacing={1} sx={{ p: 1 }}>
                              {pluginCollection.plugins.map((plugin) => (
                                <Typography
                                  key={typeof plugin === 'string' ? plugin : plugin.id}
                                  variant="body2"
                                >
                                  {typeof plugin === 'string' ? plugin : plugin.id}
                                </Typography>
                              ))}
                            </Stack>
                          </Box>
                        }
                      >
                        <Typography variant="body2" color="primary">
                          {(pluginCollection.plugins ?? []).length} plugin
                          {(pluginCollection.plugins ?? []).length === 1 ? '' : 's'}
                        </Typography>
                      </Tooltip>
                    ) : (
                      '0 plugins'
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`${ROUTES.redteam.plugins}/${pluginCollection.id}`)}
                  >
                    {pluginCollection.user}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`${ROUTES.redteam.plugins}/${pluginCollection.id}`)}
                  >
                    {format(new Date(pluginCollection.updatedAt), 'MMM d, yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => navigate(`${ROUTES.redteam.plugins}/${pluginCollection.id}`)}
                      title="Edit"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleCopy(pluginCollection.id)} title="Copy">
                      <ContentCopy />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(pluginCollection.id)} title="Delete">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Card>
    </Box>
  );
}
