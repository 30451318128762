import type { RedteamPlugin as RedteamPluginType } from '@promptfoo/redteam/types';
import { RedteamPlugin, RedteamRunOptions, RedteamStrategy } from '@promptfoo/types';
import { z } from 'zod';

export type RedteamConfigDTO = {
  id: string;
  name: string;
  type: string;
  config: RedteamConfig;
  organizationId: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  user?: string;
};

// Types needed for the UI

export interface BrowserStep {
  action: 'navigate' | 'click' | 'type' | 'extract' | 'screenshot' | 'wait' | 'waitForNewChildren';
  args?: {
    url?: string;
    selector?: string;
    text?: string;
    path?: string;
    ms?: number;
    fullPage?: boolean;
    parentSelector?: string;
    delay?: number;
    timeout?: number;
    optional?: boolean;
  };
  name?: string;
}

export interface UIProviderOptions {
  id: string;
  label?: string;
  config: {
    // Custom provider config can have anything
    [key: string]: any;

    type?: 'http' | 'websocket' | 'browser';
    // HTTP/WebSocket specific options
    url?: string;
    method?: string;
    headers?: Record<string, string>;
    body?: string | object;
    messageTemplate?: string;
    // Browser specific options
    steps?: BrowserStep[];
    headless?: boolean;
    timeoutMs?: number;
    transformResponse?: string;
    sessionParser?: string;
    cookies?:
      | Array<{
          name: string;
          value: string;
          domain?: string;
          path?: string;
        }>
      | string;
    stateful?: boolean;
    sessionSource?: 'server' | 'client';
  };
}

export interface RedteamConfig {
  id?: string;
  sharing?: boolean;
  description: string;
  prompts: string[];
  target: UIProviderOptions;
  plugins: (RedteamPlugin | { id: string; config?: any })[];
  strategies: RedteamStrategy[];
  purpose?: string;
  numTests?: number;
  extensions?: string[];
  applicationDefinition: {
    purpose?: string;
    systemPrompt?: string;
    redteamUser?: string;
    accessToData?: string;
    forbiddenData?: string;
    accessToActions?: string;
    forbiddenActions?: string;
    connectedSystems?: string;
  };
  entities: string[];
  updatedAt?: Date;
  createdAt?: Date;
  userId?: string;
  organizationId?: string;
  runOptions?: RedteamRunOptions;
}

export interface UpdatePluginCollectionRequest {
  plugins?: RedteamPlugin[];
  name?: string;
  description?: string;
}

export interface PluginCollectionResponse {
  id: string;
  plugins: RedteamPlugin[];
  organizationId: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  version: number;
  user?: string;
  description: string | null;
  name: string;
}

export const CreatePluginCollectionSchema = z.object({
  name: z.string().min(1, 'Name is required').trim(),
  description: z.string().nullable().optional(),
  plugins: z
    .array(z.any() as z.ZodType<RedteamPluginType>)
    .min(1, 'At least one plugin must be selected'),
});

export type PluginCollectionFormData = z.infer<typeof CreatePluginCollectionSchema>;
export type CreatePluginCollectionRequest = z.infer<typeof CreatePluginCollectionSchema>;
