import React from 'react';
import { useNavigate } from 'react-router-dom';
import { callApi } from '@cloud-ui/utils/api';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import type { ResultLightweightWithLabel } from '@promptfoo/types';

type SortField = 'description' | 'createdAt' | 'evalId';

interface ExtendedResultLightweightWithLabel extends ResultLightweightWithLabel {
  targetId?: string | null;
}

const ReportIndex: React.FC = () => {
  const navigate = useNavigate();
  const [recentEvals, setRecentEvals] = React.useState<ExtendedResultLightweightWithLabel[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sortField, setSortField] = React.useState<SortField>('createdAt');
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('desc');
  const [selectedTarget, setSelectedTarget] = React.useState<string>('all');

  React.useEffect(() => {
    const fetchRecentEvals = async () => {
      try {
        const resp = await callApi('/dashboard/evals', { cache: 'no-store' });
        if (!resp.ok) {
          console.error('Failed to fetch recent evals');
          return;
        }
        const body = (await resp.json()) as ExtendedResultLightweightWithLabel[];
        // Only show redteam evals
        const redteamEvals = body.filter((eval_) => eval_.isRedteam);
        setRecentEvals(redteamEvals);
      } catch (error) {
        console.error('Error fetching recent evals:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecentEvals();
  }, []);

  const handleSort = (field: SortField) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortField(field);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  const targets = React.useMemo(() => {
    const uniqueTargets = new Set<string>();
    recentEvals.forEach((eval_) => {
      if (eval_.targetId) {
        uniqueTargets.add(eval_.targetId);
      }
    });
    return Array.from(uniqueTargets).sort();
  }, [recentEvals]);

  const filteredAndSortedEvals = React.useMemo(() => {
    let filtered = [...recentEvals];
    if (selectedTarget !== 'all') {
      filtered = filtered.filter((eval_) => eval_.targetId === selectedTarget);
    }

    return filtered.sort((a, b) => {
      const multiplier = sortOrder === 'asc' ? 1 : -1;

      switch (sortField) {
        case 'createdAt':
          return multiplier * (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        case 'description':
          return multiplier * (a.description || '').localeCompare(b.description || '');
        case 'evalId':
          return multiplier * a.evalId.localeCompare(b.evalId);
        default:
          return 0;
      }
    });
  }, [recentEvals, sortField, sortOrder, selectedTarget]);

  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" color="text.secondary">
          Loading...
        </Typography>
      </Box>
    );
  }

  if (recentEvals.length === 0) {
    return (
      <Container maxWidth="md" sx={{ mt: 8 }}>
        <Paper elevation={0} sx={{ p: 4, textAlign: 'center', bgcolor: 'background.default' }}>
          <Typography variant="h5" gutterBottom color="text.primary" sx={{ fontWeight: 500 }}>
            No Red Team evaluations found
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            Run a red team evaluation first to see results here.
          </Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Recent Reports
        </Typography>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel id="target-select-label">Filter by Target</InputLabel>
          <Select
            labelId="target-select-label"
            value={selectedTarget}
            label="Filter by Target"
            onChange={(e) => setSelectedTarget(e.target.value)}
          >
            <MenuItem value="all">All Targets</MenuItem>
            {targets.map((target) => (
              <MenuItem key={target} value={target}>
                {target}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Paper elevation={0} sx={{ overflow: 'hidden', bgcolor: 'background.paper' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 500, py: 2 }}>
                  <TableSortLabel
                    active={sortField === 'description'}
                    direction={sortField === 'description' ? sortOrder : 'asc'}
                    onClick={() => handleSort('description')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontWeight: 500, py: 2 }}>Target</TableCell>
                <TableCell sx={{ fontWeight: 500, py: 2 }}>
                  <TableSortLabel
                    active={sortField === 'createdAt'}
                    direction={sortField === 'createdAt' ? sortOrder : 'asc'}
                    onClick={() => handleSort('createdAt')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontWeight: 500, py: 2 }}>
                  <TableSortLabel
                    active={sortField === 'evalId'}
                    direction={sortField === 'evalId' ? sortOrder : 'asc'}
                    onClick={() => handleSort('evalId')}
                  >
                    Eval ID
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedEvals.map((eval_) => (
                <TableRow
                  key={eval_.evalId}
                  hover
                  onClick={() => navigate(`/redteam/report/${eval_.evalId}`)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                >
                  <TableCell sx={{ py: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 500, color: 'text.primary' }}>
                      {eval_.description || 'Untitled Evaluation'}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 2 }}>
                    <Chip
                      label={eval_.targetId || 'No target'}
                      size="small"
                      sx={{
                        bgcolor: eval_.targetId ? 'primary.50' : 'grey.100',
                        color: eval_.targetId ? 'primary.700' : 'text.secondary',
                        fontWeight: 500,
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ py: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(eval_.createdAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'monospace',
                        color: 'text.secondary',
                        fontSize: '0.875rem',
                      }}
                    >
                      {eval_.evalId}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default ReportIndex;
