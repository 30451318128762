import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ConfigModal from '@cloud-ui/components/ConfigModal';
import ErrorAlert from '@cloud-ui/components/ErrorAlert';
import ErrorMessage from '@cloud-ui/components/ErrorMessage';
import LoadingSpinner from '@cloud-ui/components/LoadingSpinner';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { useJobLogs } from '@cloud-ui/hooks/useJobLogs';
import { getJobDetails, listJobs, startJob, stopJob } from '@cloud-ui/utils/api/jobs';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ReplayIcon from '@mui/icons-material/Replay';
import StopIcon from '@mui/icons-material/Stop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { RedteamRunOptions, UnifiedConfig } from '@promptfoo/types';
import type { EvalJobDTO } from '@shared/dto';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { LogViewer } from '../redteam/configs/edit/components/LogViewer';

export default function Jobs() {
  const { id: incJobId } = useParams();
  const [selectedJobId, setSelectedJobId] = useState<string | null | undefined>(incJobId);
  const [isConfigDialogOpen, setIsConfigDialogOpen] = useState(false);
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const {
    data: jobs = [],
    isLoading: isLoadingJobs,
    error: jobsError,
    isError: isJobsError,
  } = useQuery({ queryKey: ['jobs'], queryFn: listJobs, staleTime: 0 });

  const {
    data: selectedJob,
    isLoading: isLoadingJob,
    error: getSelectedJobError,
    isError: isGetSelectedJobError,
  } = useQuery({
    queryKey: ['job', selectedJobId],
    queryFn: () => getJobDetails(selectedJobId || ''),
    enabled: !!selectedJobId,
  });

  if (isGetSelectedJobError) {
    showToast(getSelectedJobError?.message, 'error');
  }

  const { logs, error: logsError } = useJobLogs(selectedJobId, {
    onMessage: (data) => {
      if (data.type === 'progress' && selectedJob) {
        queryClient.setQueryData(['job', selectedJobId], {
          ...selectedJob,
          progress: data.progress,
        });
      }
    },
    maxLogs: 1000,
    batchInterval: 500,
  });

  const { mutate: runRedTeam } = useMutation({
    mutationFn: ({
      config,
      configId,
      options,
    }: {
      config: UnifiedConfig;
      configId?: string | null;
      options: RedteamRunOptions;
    }) => startJob(config, configId, options),
    onSuccess: (data) => {
      showToast('Job started', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs'] });

      setSelectedJobId(data.id);
    },
    onError: (error) => {
      showToast(`Error starting new job: ${error}`, 'error');
    },
  });

  const { mutate: stopJobMutation } = useMutation({
    mutationFn: stopJob,
    onSuccess: () => {
      showToast('Job stopped', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
      queryClient.invalidateQueries({ queryKey: ['job', selectedJobId] });
    },
    onError: (error) => {
      showToast(`Error stopping job: ${error}`, 'error');
    },
  });

  const handleJobClick = (job: any) => {
    queryClient.setQueryData(['jobLogs', selectedJobId], []);
    setSelectedJobId(job.id);
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'running':
        return 'primary';
      case 'completed':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'default';
    }
  };

  const handleOpenConfigDialog = () => {
    setIsConfigDialogOpen(true);
  };

  const handleCloseConfigDialog = () => {
    setIsConfigDialogOpen(false);
  };

  if (isLoadingJobs) {
    return <LoadingSpinner />;
  }

  if (isJobsError) {
    return <ErrorMessage error={jobsError} />;
  }

  return (
    <Box maxWidth="100%" mx="auto" p={3}>
      <Typography variant="h4" gutterBottom>
        Red Team Jobs
      </Typography>

      <Box display="flex" gap={3}>
        {/* Left side - Jobs Table */}
        <Box flex={1}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Eval ID</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs.map((job: EvalJobDTO) => (
                  <TableRow
                    key={job.id}
                    hover
                    onClick={() => handleJobClick(job)}
                    sx={{ cursor: 'pointer' }}
                    selected={selectedJobId === job.id}
                  >
                    <TableCell>{job.id}</TableCell>
                    <TableCell>
                      <Typography
                        component="span"
                        title={new Date(job.createdAt).toLocaleString(undefined, {
                          timeZoneName: 'short',
                          timeZone: 'UTC',
                        })}
                      >
                        {formatDistanceToNow(parseISO(job.createdAt as unknown as string), {
                          addSuffix: true,
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip label={job.status} color={getStatusColor(job.status)} size="small" />
                    </TableCell>
                    <TableCell>
                      {job.evalId ? (
                        <Link
                          to={`${ROUTES.eval}/${job.evalId}`}
                          onClick={(e) => e.stopPropagation()}
                          style={{ textDecoration: 'none' }}
                        >
                          {job.evalId}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>{job.config?.description || 'No description'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Right side - Job Details */}
        <Box flex={1}>
          {isJobsError && <ErrorAlert error={jobsError} />}
          {logsError && <ErrorAlert error={logsError} />}

          {selectedJob && (
            <Paper sx={{ p: 3, height: '100%' }}>
              {isLoadingJob ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 3,
                    }}
                  >
                    <Typography variant="h6">Job Details</Typography>
                    <Box>
                      <Button
                        startIcon={<VisibilityIcon />}
                        onClick={handleOpenConfigDialog}
                        sx={{ mr: 1 }}
                      >
                        View Config
                      </Button>
                      {selectedJob.status === 'running' && (
                        <Button
                          startIcon={<StopIcon />}
                          onClick={() => stopJobMutation(selectedJob.id)}
                          color="error"
                          sx={{ mr: 1 }}
                        >
                          Stop
                        </Button>
                      )}
                      {selectedJob.status === 'completed' && selectedJob.evalId && (
                        <Button
                          component={Link}
                          to={`${ROUTES.redteam.report}/${selectedJob.evalId}`}
                          startIcon={<AssessmentIcon />}
                          color="primary"
                          sx={{ mr: 1 }}
                        >
                          View Report
                        </Button>
                      )}
                      <Button
                        startIcon={<ReplayIcon />}
                        onClick={() =>
                          runRedTeam({
                            config: selectedJob.config as UnifiedConfig,
                            configId: selectedJob.configId,
                            options: selectedJob.options,
                          })
                        }
                        color="primary"
                      >
                        Rerun
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                      gap: 3,
                      mb: 3,
                      '& > div': {
                        minWidth: 0, // Prevents overflow in grid items
                      },
                    }}
                  >
                    <Box>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        ID
                      </Typography>
                      <Typography variant="caption" sx={{ fontFamily: 'monospace' }}>
                        {selectedJob.id}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Created
                      </Typography>
                      <Typography
                        variant="body1"
                        title={new Date(selectedJob.createdAt).toLocaleString(undefined, {
                          timeZoneName: 'short',
                          timeZone: 'UTC',
                        })}
                      >
                        {formatDistanceToNow(parseISO(selectedJob.createdAt as unknown as string), {
                          addSuffix: true,
                        })}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Status
                      </Typography>
                      <Chip
                        label={
                          selectedJob.progress?.completed === selectedJob.progress?.total &&
                          selectedJob.progress?.total &&
                          selectedJob.progress?.total > 0
                            ? 'completed'
                            : selectedJob.status
                        }
                        color={
                          selectedJob.progress?.completed === selectedJob.progress?.total &&
                          selectedJob.progress?.total &&
                          selectedJob.progress?.total > 0
                            ? 'success'
                            : getStatusColor(selectedJob.status)
                        }
                        size="small"
                      />
                    </Box>
                  </Box>

                  {selectedJob.progress && (
                    <>
                      {selectedJob.progress.total > 0 && (
                        <Box sx={{ mb: 3 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 4 }}>
                            {selectedJob.progress.completed > 0 && (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body2" color="text.secondary">
                                  Pass Rate:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    ml: 1,
                                  }}
                                >
                                  {Math.round(
                                    (selectedJob.progress.passes / selectedJob.progress.completed) *
                                      100,
                                  )}
                                  %
                                </Typography>
                              </Box>
                            )}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Progress:
                              </Typography>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {selectedJob.progress.completed} / {selectedJob.progress.total}
                              </Typography>
                            </Box>
                          </Box>
                          <LinearProgress
                            variant="determinate"
                            value={
                              (selectedJob.progress.completed / selectedJob.progress.total) * 100
                            }
                            sx={{
                              height: 6,
                              borderRadius: 3,
                              bgcolor: 'grey.100',
                              '& .MuiLinearProgress-bar': {
                                bgcolor: 'primary.main',
                              },
                            }}
                          />
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 3,
                          mb: 3,
                          '& > div': {
                            flex: 1,
                            minWidth: '120px',
                            p: 2,
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: 'divider',
                            bgcolor: 'background.paper',
                          },
                        }}
                      >
                        <Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <CheckCircleIcon
                              sx={{ mr: 1, fontSize: '1.2rem', color: 'success.main' }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              Passes
                            </Typography>
                          </Box>
                          <Typography variant="h5" color="text.primary" sx={{ fontWeight: 500 }}>
                            {selectedJob.progress.passes}
                          </Typography>
                        </Box>
                        <Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <ErrorIcon sx={{ mr: 1, fontSize: '1.2rem', color: 'error.main' }} />
                            <Typography variant="body2" color="text.secondary">
                              Failures
                            </Typography>
                          </Box>
                          <Typography variant="h5" color="text.primary" sx={{ fontWeight: 500 }}>
                            {selectedJob.progress.failures}
                          </Typography>
                        </Box>
                        <Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <WarningIcon
                              sx={{ mr: 1, fontSize: '1.2rem', color: 'warning.main' }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              Errors
                            </Typography>
                          </Box>
                          <Typography variant="h5" color="text.primary" sx={{ fontWeight: 500 }}>
                            {selectedJob.progress.errors}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}

                  <Box sx={{ mt: 3 }}>
                    {logs.length === 0 && !logsError ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                          Loading logs...
                        </Typography>
                      </Box>
                    ) : (
                      <LogViewer logs={logs} />
                    )}
                  </Box>
                </>
              )}
            </Paper>
          )}

          <ConfigModal
            open={isConfigDialogOpen}
            onClose={handleCloseConfigDialog}
            config={selectedJob?.config}
            title="Job Configuration"
          />
        </Box>
      </Box>
    </Box>
  );
}
