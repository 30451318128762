import React from 'react';
import Editor from 'react-simple-code-editor';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
// @ts-expect-error: No types available
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-yaml';
import 'prismjs/themes/prism.css';

// Define supported languages without exposing Prism implementation
export type SupportedLanguage = 'javascript' | 'python' | 'yaml' | 'json' | 'plaintext';

interface CodeEditorProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  minHeight?: string;
  language?: SupportedLanguage;
  formatOnBlur?: boolean;
  formatFn?: (code: string) => string;
}

const defaultFormatters: Record<SupportedLanguage, (code: string) => string> = {
  javascript: (code) => {
    try {
      return JSON.stringify(JSON.parse(code), null, 2);
    } catch (error) {
      throw new Error(`Invalid JSON format: ${error}`);
    }
  },
  json: (code) => {
    try {
      return JSON.stringify(JSON.parse(code), null, 2);
    } catch (error) {
      throw new Error(`Invalid JSON format: ${error}`);
    }
  },
  yaml: (code) => code,
  python: (code) => code,
  plaintext: (code) => code,
};

const languageMap: Record<SupportedLanguage, any> = {
  javascript: languages.javascript,
  python: languages.python,
  yaml: languages.yaml,
  json: languages.json,
  plaintext: languages.plaintext || {},
};

export default function CodeEditor({
  label,
  value,
  onChange,
  placeholder,
  minHeight = '100px',
  language = 'plaintext',
  formatOnBlur = false,
  formatFn,
}: CodeEditorProps) {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const [formatError, setFormatError] = React.useState<string | null>(null);

  const formatCode = React.useCallback(() => {
    const formatter = formatFn || defaultFormatters[language];
    try {
      const formatted = formatter(value);
      if (formatted !== value) {
        onChange(formatted);
      }
      setFormatError(null);
    } catch (error) {
      setFormatError(error instanceof Error ? error.message : 'Invalid format');
      console.error('Format error:', error);
    }
  }, [value, onChange, formatFn, language]);

  const handleBlur = React.useCallback(() => {
    if (formatOnBlur) {
      formatCode();
    }
  }, [formatOnBlur, formatCode]);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
        <IconButton onClick={formatCode} size="small" title="Format code">
          <FormatIndentIncreaseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          border: 1,
          borderColor: formatError ? 'error.main' : theme.palette.divider,
          borderRadius: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
          '& .token': {
            background: 'transparent !important',
          },
          mt: 1,
          position: 'relative',
          minHeight,
        }}
      >
        <Editor
          value={value}
          onValueChange={onChange}
          onBlur={handleBlur}
          highlight={(code) => highlight(code, languageMap[language])}
          padding={8}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 14,
            backgroundColor: darkMode ? '#1e1e1e' : '#f5f5f5',
            color: theme.palette.text.primary,
            minHeight,
          }}
          placeholder={placeholder}
          className={darkMode ? 'dark-syntax' : ''}
        />
      </Box>
      {formatError && (
        <Typography color="error" variant="caption" sx={{ mt: 1, display: 'block' }}>
          {formatError}
        </Typography>
      )}
    </Box>
  );
}
