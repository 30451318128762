import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorFallback from '@cloud-ui/components/ErrorFallback';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import {
  getPluginCollection,
  createPluginCollection,
  updatePluginCollection,
} from '@cloud-ui/utils/api/redteam';
import { createZodValidation } from '@cloud-ui/utils/formikValidation';
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, TextField, Typography, Alert } from '@mui/material';
import type { RedteamPlugin } from '@promptfoo/redteam/types';
import { type UpdatePluginCollectionRequest } from '@shared/dto/redteamConfigs';
import { CreatePluginCollectionSchema } from '@shared/dto/redteamConfigs';
import { useFormik } from 'formik';
import Plugins from '../components/plugins/Plugins';

interface PluginsProps {
  value: RedteamPlugin[];
  onChange: (newPlugins: RedteamPlugin[]) => void;
  onBlur: () => void;
  error?: string | string[] | false;
  showCustomSections: boolean;
}

function PluginSelector({ value, onChange, onBlur, error }: PluginsProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box>
        <Plugins
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          showCustomSections={true}
        />
      </Box>
    </ErrorBoundary>
  );
}

const MemoizedPlugins = React.memo<PluginsProps>(PluginSelector);

export default function EditPluginCollectionPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const isNew = id === 'new';

  const saveMutation = useMutation({
    mutationFn: (data: UpdatePluginCollectionRequest) => {
      if (isNew) {
        const createData = {
          name: data.name!,
          description: data.description,
          plugins: data.plugins || [],
        };
        return createPluginCollection(createData);
      }
      return updatePluginCollection(id!, data);
    },
    onSuccess: async (savedPluginCollection) => {
      await queryClient.invalidateQueries({ queryKey: ['pluginCollections'] });
      queryClient.setQueryData(['pluginCollection', id], savedPluginCollection);
      showToast('Plugin collection saved successfully', 'success');
    },
    onError: (error) => {
      console.error('Failed to save plugin collection:', error);
      showToast('Failed to save plugin collection', 'error');
    },
  });
  const {
    data: pluginCollection,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['pluginCollection', id],
    queryFn: () => (isNew ? null : getPluginCollection(id!)),
    enabled: !isNew,
    retry: false,
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      plugins: [] as RedteamPlugin[],
    },
    validate: createZodValidation(CreatePluginCollectionSchema),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const result = await saveMutation.mutateAsync({
          ...(pluginCollection || {}),
          ...values,
        });
        if (isNew && result.id) {
          navigate(`${ROUTES.redteam.plugins}/${result.id}`);
        }
      } catch (error) {
        console.error('Failed to save plugin collection:', error);
        showToast('Failed to save plugin collection', 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (error) {
      showToast('Failed to load plugin collection', 'error');
      navigate(ROUTES.redteam.plugins);
    }
  }, [error, navigate]);

  // Update form values when plugin collection data loads
  useEffect(() => {
    if (pluginCollection) {
      formik.setValues({
        name: pluginCollection.name || '',
        description: pluginCollection.description || '',
        plugins: pluginCollection.plugins || [],
      });
    }
  }, [pluginCollection]);

  const handlePluginsChange = React.useCallback(
    (newPlugins: RedteamPlugin[]) => {
      console.log({ newPlugins });
      // Skip if no actual change
      if (!newPlugins || !formik.values.plugins) {
        return;
      }

      // Create shallow copies before sorting to avoid mutating state
      const currentPluginsSorted = [...formik.values.plugins].sort((a, b) => {
        const aId = typeof a === 'string' ? a : a.id;
        const bId = typeof b === 'string' ? b : b.id;
        return aId.localeCompare(bId);
      });
      const newPluginsSorted = [...newPlugins].sort((a, b) => {
        const aId = typeof a === 'string' ? a : a.id;
        const bId = typeof b === 'string' ? b : b.id;
        return aId.localeCompare(bId);
      });

      // Set plugins only if they are different
      if (JSON.stringify(currentPluginsSorted) !== JSON.stringify(newPluginsSorted)) {
        formik.setFieldValue('plugins', newPlugins);
      }
    },
    [formik.setFieldValue, formik.values.plugins],
  );

  const handlePluginsBlur = React.useCallback(() => {
    formik.setFieldTouched('plugins');
  }, [formik.setFieldTouched]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth="1200px" mx="auto" p={4}>
      <Box mb={5}>
        <Button
          variant="text"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => navigate(ROUTES.redteam.plugins)}
          sx={{ ml: -2 }}
        >
          Back to Plugin Collections
        </Button>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" alignItems="center" mb={5} justifyContent="space-between">
          <Typography variant="h4" sx={{ fontWeight: 500 }}>
            {isNew ? 'New Plugin Collection' : 'Edit Plugin Collection'}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            size="large"
            sx={{ px: 3 }}
          >
            Save Changes
          </Button>
        </Box>

        {Object.keys(formik.errors).length > 0 && (
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              To {isNew ? 'create' : 'update'} a plugin collection, please:
            </Typography>
            <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
              {!formik.values.name && <li>Add a name for your collection</li>}
              {(!formik.values.plugins || formik.values.plugins.length === 0) && (
                <li>Select at least one plugin from the list below</li>
              )}
              {Object.entries(formik.errors).map(([field, error]) => {
                // Skip name and plugins as they're handled above
                if (field !== 'name' && field !== 'plugins') {
                  return <li key={field}>{error as string}</li>;
                }
                return null;
              })}
            </ul>
          </Alert>
        )}

        <Box mb={6}>
          <Box display="flex" flexDirection="column" gap={3} mb={4}>
            <TextField
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              variant="outlined"
              required
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'background.paper',
                },
              }}
            />
            <TextField
              label="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'background.paper',
                },
              }}
            />
          </Box>

          {formik.touched.plugins && formik.errors.plugins && (
            <Typography
              color="error"
              variant="body2"
              sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {formik.errors.plugins}
            </Typography>
          )}

          <MemoizedPlugins
            value={formik.values.plugins}
            onChange={handlePluginsChange}
            onBlur={handlePluginsBlur}
            error={formik.touched.plugins && formik.errors.plugins}
            showCustomSections={true}
          />
        </Box>
      </form>
    </Box>
  );
}
