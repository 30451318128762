import React, { memo, useState, useCallback, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

/**
 * PolicyItem component encapsulates a single policy text field.
 * It maintains an internal local state for the text field so that
 * fast typing doesn't trigger re-renders of the entire CustomPoliciesSection.
 */
const PolicyItem = memo(
  ({
    index,
    policy,
    onUpdatePolicy,
    onRemovePolicy,
    readOnly,
  }: {
    index: number;
    policy: string;
    onUpdatePolicy: (index: number, newPolicy: string) => void;
    onRemovePolicy: () => void;
    readOnly?: boolean;
  }) => {
    // Local state for the text field value.
    const [localPolicy, setLocalPolicy] = useState(policy);
    // Local state to control the expansion of the item.
    const [expanded, setExpanded] = useState(true);

    // Sync local state if the parent's policy changes externally.
    useEffect(() => {
      setLocalPolicy(policy);
    }, [policy]);

    const toggleExpansion = useCallback(() => {
      setExpanded((prev) => !prev);
    }, []);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      setLocalPolicy(e.target.value);
    }, []);

    // Update the parent only when the input loses focus.
    const handleBlur = useCallback(() => {
      onUpdatePolicy(index, localPolicy);
    }, [index, localPolicy, onUpdatePolicy]);

    return (
      <Box
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle1" sx={{ flex: 1 }}>
            Custom Policy {index + 1}
          </Typography>
          <IconButton onClick={toggleExpansion}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          {!readOnly && (
            <IconButton onClick={onRemovePolicy} color="error">
              <DeleteIcon />
            </IconButton>
          )}
        </Box>

        <Collapse in={expanded}>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Policy Text"
              value={localPolicy}
              onChange={handleChange}
              onBlur={handleBlur}
              multiline
              rows={4}
              fullWidth
              placeholder="Enter your policy guidelines here..."
            />
          </Box>
        </Collapse>
      </Box>
    );
  },
);
PolicyItem.displayName = 'PolicyItem';

export interface PolicyPlugin {
  id: 'policy';
  config: {
    policy: string;
  };
}

export interface CustomPoliciesSectionProps {
  policies: string[];
  onChange: (policies: string[]) => void;
  readOnly?: boolean;
}

export const CustomPoliciesSection = ({
  policies,
  onChange,
  readOnly,
}: CustomPoliciesSectionProps) => {
  const handleAddPolicy = useCallback(() => {
    onChange([...policies, '']);
  }, [policies, onChange]);

  const handleUpdatePolicy = useCallback(
    (index: number, newPolicy: string) => {
      const newPolicies = policies.map((p, i) => (i === index ? newPolicy : p));
      onChange(newPolicies);
    },
    [policies, onChange],
  );

  const handleRemovePolicy = useCallback(
    (index: number) => {
      onChange(policies.filter((_, i) => i !== index));
    },
    [policies, onChange],
  );

  return (
    <Card variant="outlined" sx={{ mb: 3 }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6">Custom Policies</Typography>
          {!readOnly && (
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddPolicy}
              variant="contained"
              color="primary"
            >
              Add Policy
            </Button>
          )}
        </Box>

        <Stack spacing={2}>
          {policies.map((policy, index) => (
            <PolicyItem
              key={`policy-${index}-${policy.substring(0, 10)}`}
              index={index}
              policy={policy}
              onUpdatePolicy={handleUpdatePolicy}
              onRemovePolicy={() => handleRemovePolicy(index)}
              readOnly={readOnly}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

CustomPoliciesSection.displayName = 'CustomPoliciesSection';
