import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import PageShell from '@cloud-ui/components/PageShell';
import { ROUTES } from '@cloud-ui/constants';
import { AuthProvider, RequireAuth } from '@cloud-ui/contexts/AuthContext';
import { ConfigProvider } from '@cloud-ui/contexts/ConfigContext';
import { ToastProvider } from '@cloud-ui/contexts/ToastContext';
import NotFoundPage from '@cloud-ui/pages/NotFound';
import LoginPage from '@cloud-ui/pages/auth/login/Login';
import RequestPasswordReset from '@cloud-ui/pages/auth/request-reset-password/RequestPasswordReset';
import ResetPassword from '@cloud-ui/pages/auth/reset-password/ResetPassword';
import ResultsPage from '@cloud-ui/pages/eval/page';
import OrganizationPage from '@cloud-ui/pages/organization';
import RedteamConfigsListPage from '@cloud-ui/pages/redteam/configs/list';
import RedteamDashboardPage from '@cloud-ui/pages/redteam/dashboard/page';
import RedteamReportPage from '@cloud-ui/pages/redteam/report/page';
import WelcomePage from '@cloud-ui/pages/welcome/page';
import LoggedInResetPasswordPage from './pages/auth/logged-in-reset-password/page';
import GuardrailsDashboard from './pages/guardrails/page';
import Jobs from './pages/jobs/jobs';
import RedteamConfigsEditPage from './pages/redteam/configs/edit/edit';
import IssuesPage from './pages/redteam/issues/page';
import EditPluginSetPage from './pages/redteam/plugins/edit';
import PluginSetsListPage from './pages/redteam/plugins/list';
import ServerSettingsEditPage from './pages/serverSettings/edit';
import ServerSettingsPage from './pages/serverSettings/page';
import VerifyEmailPage from './pages/verify-email';
import { ENABLE_SERVER_SETTINGS_PAGE } from './utils/serverSettings';

const DEFAULT_ROUTE = ROUTES.redteam.dashboard;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
      gcTime: 10 * 60 * 1000, // 10 minutes
    },
  },
});
const ProtectedRoutes = () => (
  <RequireAuth>
    <Outlet />
  </RequireAuth>
);
const SentryRoutes = Sentry.withSentryRouting(Routes);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
      <ConfigProvider>
        <AuthProvider>
          <ToastProvider>
            <Router>
              <PageShell>
                <SentryRoutes>
                  <Route path="/" element={<Navigate to={DEFAULT_ROUTE} replace />} />

                  {/* Public routes */}
                  <Route path={ROUTES.login} element={<LoginPage />} />
                  <Route path={ROUTES.verifyEmail} element={<VerifyEmailPage />} />
                  <Route path={ROUTES.passwordReset.request} element={<RequestPasswordReset />} />
                  <Route path={ROUTES.passwordReset.confirm} element={<ResetPassword />} />

                  {/* Evals and Reports are private but can be made public by the user */}
                  <Route path={`${ROUTES.eval}/:id`} element={<ResultsPage />} />
                  <Route path={`${ROUTES.redteam.report}/:id`} element={<RedteamReportPage />} />

                  {/* Protected routes */}
                  <Route element={<ProtectedRoutes />}>
                    <Route path={ROUTES.welcome} element={<WelcomePage />} />
                    <Route
                      path={ROUTES.loggedInPasswordReset}
                      element={<LoggedInResetPasswordPage />}
                    />
                    <Route path={ROUTES.eval} element={<ResultsPage />} />
                    <Route path={`${ROUTES.organization}/:orgId`} element={<OrganizationPage />} />
                    <Route path={ROUTES.guardrails} element={<GuardrailsDashboard />} />
                    <Route path={ROUTES.redteam.dashboard} element={<RedteamDashboardPage />} />
                    <Route
                      path={`${ROUTES.redteam.dashboard}/:targetId`}
                      element={<RedteamDashboardPage />}
                    />
                    <Route path={ROUTES.redteam.report} element={<RedteamReportPage />} />
                    <Route path={ROUTES.redteam.configs} element={<RedteamConfigsListPage />} />
                    <Route
                      path={`${ROUTES.redteam.configs}/:id`}
                      element={<RedteamConfigsEditPage />}
                    />
                    <Route path={ROUTES.redteam.plugins} element={<PluginSetsListPage />} />
                    <Route path={`${ROUTES.redteam.plugins}/:id`} element={<EditPluginSetPage />} />
                    <Route path={ROUTES.jobs} element={<Jobs />} />
                    <Route path={`${ROUTES.jobs}/:id`} element={<Jobs />} />

                    <Route path={ROUTES.redteam.vulnerabilities} element={<IssuesPage />} />
                    <Route
                      path={`${ROUTES.redteam.vulnerabilities}/:issueId`}
                      element={<IssuesPage />}
                    />
                    {ENABLE_SERVER_SETTINGS_PAGE && (
                      <Route path={ROUTES.serverSettings.index} element={<ServerSettingsPage />}>
                        <Route index element={<ServerSettingsEditPage />} />
                        <Route
                          path={`${ROUTES.serverSettings.edit}/:key`}
                          element={<ServerSettingsEditPage />}
                        />
                      </Route>
                    )}
                  </Route>

                  {/* Catch-all + explicit entry for 404 Not Found */}
                  <Route path={ROUTES.notFound} element={<NotFoundPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </SentryRoutes>
              </PageShell>
            </Router>
          </ToastProvider>
        </AuthProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
