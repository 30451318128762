import { z } from 'zod';

/**
 * Creates a Formik validation function using a Zod schema
 * @param schema - Zod schema to validate against
 * @returns A validation function compatible with Formik's validate prop
 */
export const createZodValidation = <T>(schema: z.ZodSchema<T>) => {
  return async (values: T) => {
    try {
      await schema.parseAsync(values);
      return {};
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error('Zod validation error', error);
        return error.issues.reduce(
          (acc, issue) => {
            const path = issue.path.join('.');
            acc[path] = issue.message;
            return acc;
          },
          {} as Record<string, string>,
        );
      }
      console.error('Validation failed', error);
      return { submit: 'Validation failed' };
    }
  };
};
