import { type categoryAliases, categoryAliasesReverse } from '@promptfoo/redteam/constants';
import type { EvaluateResult, GradingResult } from '@promptfoo/types';

// A constant lookup object for metric suffixes that have direct mappings.
const suffixToStrategy: Record<string, string> = {
  Base64: 'base64',
  BestOfN: 'best-of-n',
  Citation: 'citation',
  Crescendo: 'crescendo',
  GCG: 'gcg',
  GOAT: 'goat',
  Injection: 'prompt-injection',
  Iterative: 'jailbreak',
  Composite: 'jailbreak:composite',
  Likert: 'jailbreak:likert',
  IterativeTree: 'jailbreak:tree',
  Leetspeak: 'leetspeak',
  Rot13: 'rot13',
  Pandamonium: 'pandamonium',
};

export function getStrategyIdFromMetric(metric: string): string | null {
  const parts = metric.split('/');
  const metricSuffix = parts[1];
  if (!metricSuffix) {
    return null;
  }

  // Check for a direct mapping.
  if (suffixToStrategy[metricSuffix] !== undefined) {
    return suffixToStrategy[metricSuffix];
  }

  // Handle cases that require prefix matching.
  if (metricSuffix.startsWith('MathPrompt')) {
    return 'math-prompt';
  }

  if (metricSuffix.startsWith('Multilingual')) {
    return 'multilingual';
  }

  return null;
}

export function getStrategyIdFromGradingResult(gradingResult?: GradingResult): string | null {
  if (!gradingResult?.componentResults) {
    return null;
  }

  for (const result of gradingResult.componentResults) {
    if (result.assertion?.metric) {
      const strategyId = getStrategyIdFromMetric(result.assertion.metric);
      if (strategyId) {
        return strategyId;
      }
    }
  }
  return null;
}

export function getPluginIdFromResult(result: EvaluateResult): string | null {
  // TODO(ian): Need a much easier way to get the pluginId (and strategyId) from a result
  const harmCategory = result.vars['harmCategory'];
  if (harmCategory) {
    return categoryAliasesReverse[harmCategory as keyof typeof categoryAliases];
  }
  const metricNames =
    result.gradingResult?.componentResults?.map((result) => result.assertion?.metric) || [];
  const metricBaseName = metricNames[0]?.split('/')[0];
  if (metricBaseName) {
    return categoryAliasesReverse[metricBaseName as keyof typeof categoryAliases];
  }
  return null;
}
