import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import type { ProviderOptions } from '@promptfoo/types';
import path from 'path-browserify';
import CodeEditor from '../CodeEditor';

interface CustomProviderConfigurationProps {
  selectedProvider: ProviderOptions;
  updateCustomProvider: (field: string, value: any) => void;
  rawConfigJson: string;
  setRawConfigJson: (value: string) => void;
  bodyError: string | null;
}

export default function CustomProviderConfiguration({
  selectedProvider,
  updateCustomProvider,
  rawConfigJson,
  setRawConfigJson,
  bodyError,
}: CustomProviderConfigurationProps) {
  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.startsWith('file://')) {
      updateCustomProvider('id', value);
    } else {
      updateCustomProvider('id', value ? `file://${value}` : '');
    }
  };

  const validatePath = (pathToValidate: string): string | undefined => {
    if (!pathToValidate) {
      return 'Provider path is required';
    }
    if (!path.isAbsolute(pathToValidate)) {
      return 'Provider path must be absolute (start with /)';
    }
    if (!pathToValidate.endsWith('.js') && !pathToValidate.endsWith('.py')) {
      return 'Provider must be a .js or .py file';
    }
    return undefined;
  };

  const pathError = validatePath(selectedProvider.id?.replace('file://', '') || '');

  return (
    <Box mt={2}>
      <Typography variant="h6" gutterBottom>
        Custom Provider Configuration
      </Typography>
      <Box mt={2} p={2} border={1} borderColor="grey.300" borderRadius={1}>
        <TextField
          fullWidth
          label="Provider Path"
          value={selectedProvider.id?.replace('file://', '') ?? ''}
          onChange={handlePathChange}
          placeholder="e.g., /path/to/provider.js"
          helperText={
            pathError || 'Enter the absolute path to your JavaScript or Python provider file'
          }
          error={!!pathError}
          spellCheck={false}
        />

        {selectedProvider.id?.endsWith('.js') && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Learn how to set up a custom JavaScript provider{' '}
            <Link
              href="https://www.promptfoo.dev/docs/providers/custom-api/"
              target="_blank"
              rel="noopener"
            >
              here
            </Link>
            .
          </Typography>
        )}
        {selectedProvider.id?.endsWith('.py') && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Learn how to set up a custom Python provider{' '}
            <Link
              href="https://www.promptfoo.dev/docs/providers/python/"
              target="_blank"
              rel="noopener"
            >
              here
            </Link>
            .
          </Typography>
        )}
        <Box mt={2} p={2} border={1} borderColor="grey.300" borderRadius={1}>
          {' '}
          <CodeEditor
            label="Provider Configuration (JSON)"
            language="json"
            value={rawConfigJson}
            onChange={(value) => {
              setRawConfigJson(value);
              try {
                const config = JSON.parse(value);
                updateCustomProvider('config', config);
              } catch (error) {
                console.error('Invalid JSON configuration:', error);
              }
            }}
            placeholder={`{
  "temperature": 0.5,
  "maxTokens": 100
}`}
            minHeight="200px"
          />
        </Box>

        {bodyError && (
          <Typography color="error" variant="caption" sx={{ mt: 1 }}>
            {bodyError}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
