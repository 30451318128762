import type { RedteamConfigDTO } from '@shared/dto/redteamConfigs';
import type {
  PluginCollectionResponse,
  CreatePluginCollectionRequest,
} from '@shared/dto/redteamConfigs';
import { callApi } from '../api';

export const saveRedteamConfig = async (config: Record<string, any>): Promise<RedteamConfigDTO> => {
  const response = await callApi(`/redteam/configs`, {
    method: 'POST',
    body: JSON.stringify(config),
  });
  return await response.json();
};

export const listRedteamConfigs = async (): Promise<RedteamConfigDTO[]> => {
  const response = await callApi(`/redteam/configs`);
  return await response.json();
};

export const getRedteamConfig = async (id: string): Promise<RedteamConfigDTO> => {
  const response = await callApi(`/redteam/configs/${id}`);
  return await response.json();
};

export const deleteRedteamConfig = async (id: string): Promise<void> => {
  await callApi(`/redteam/configs/${id}`, { method: 'DELETE' });
};

export const updateRedteamConfig = async (
  id: string,
  config: Record<string, any>,
): Promise<RedteamConfigDTO> => {
  const response = await callApi(`/redteam/configs/${id}`, {
    method: 'PUT',
    body: JSON.stringify(config),
  });
  return await response.json();
};

export type PluginCollection = PluginCollectionResponse;

export async function listPluginCollections(): Promise<PluginCollectionResponse[]> {
  const response = await callApi('/redteam/plugins');
  return response.json();
}

export async function getPluginCollection(id: string): Promise<PluginCollectionResponse> {
  const response = await callApi(`/redteam/plugins/${id}`);
  return response.json();
}

export async function createPluginCollection(
  data: Omit<CreatePluginCollectionRequest, 'id'>,
): Promise<PluginCollectionResponse> {
  const response = await callApi('/redteam/plugins', {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function updatePluginCollection(
  id: string,
  data: Partial<CreatePluginCollectionRequest>,
): Promise<PluginCollectionResponse> {
  const response = await callApi(`/redteam/plugins/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function deletePluginCollection(id: string): Promise<void> {
  await callApi(`/redteam/plugins/${id}`, { method: 'DELETE' });
}
